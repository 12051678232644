import * as React from "react";
import "./styles.scss";
import mainLogo from "../images/main-logo.png";
import company1Logo from "../images/company1-logo.png";
import company2Logo from "../images/company2-logo.png";
import company3Logo from "../images/company3-logo.png";
import company4Logo from "../images/company4-logo.png";
import { Link } from "gatsby";

// markup
const IndexPage = () => {
  return (
    <main className="page">
      <title>MMILLENNIUMM</title>
      <div className="main-section">
        <img src={mainLogo} />
        <h1>MMILLENNIUMM GROUP INC.</h1>
        <h2>An Innovation Company</h2>
      </div>
      <div className="company-logos-container">
        <img src={company1Logo} />
        <div className="company-logos-middle-container">
          <img src={company2Logo} />
          <a href="https://air-sleeper.com" target='_blank'>
            <img src={company4Logo} />
          </a>
        </div>
        <img src={company3Logo} />
      </div>
      <div className="contact-us">
        <span>Contact Us:</span>
        <span><a href="tel:+1301-320-7700">+1 301 320 7700</a></span>
        <span><a href="mailto:info@mmmmg.com">info@mmmmg.com</a></span>
      </div>
      <div className="privacy-link-container">
        <Link to="/privacy">Privacy and Terms</Link>
      </div>
      <footer>
        <p>©2022 MmillenniumM Group Inc. All Rights Reserved.</p>
      </footer>
    </main>
  );
};

export default IndexPage;
